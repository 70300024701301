<template>
  <div class="text-center">
    <v-dialog
      v-model="isOpen"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          small
          v-on="on"
        >
          <v-icon
            small
            class="mr-2"
          >
            mdi-tune
          </v-icon>
          Фильтр
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <Filters
            v-model="filterLocalValues"
            :loading="loading"
          />
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              @click="handleFind"
            >Найти
            </v-btn>
            <v-btn
              @click="handleClear"
            >Очистить
            </v-btn>
            <v-btn
              color="error"
              @click="isOpen = false"
            >
              Закрыть
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Filters from '@/components/views/admin/advert/Common/Filters'
import { cleanClone, deepEqual } from '@/components/utils/common'
import AdvertFilterList from '@/components/structures/filters/advertFilterList'

export default {
  name: 'AdvertDialogFilter',
  components: { Filters },
  props: {
    loading: Boolean,
    value: Object
  },
  data () {
    return {
      isOpen: false,
      filterLocalValues: cleanClone(this.value)
    }
  },
  watch: {
    value: {
      handler () {
        if (!deepEqual(this.value, this.filterLocalValues)) {
          this.filterLocalValues = cleanClone(this.value)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleFind () {
      this.$emit('input', cleanClone(this.filterLocalValues))
      this.isOpen = false
    },
    handleClear () {
      this.filterLocalValues = new AdvertFilterList()
      this.$emit('input', cleanClone(this.filterLocalValues))
    }
  }
}
</script>

<style scoped>

</style>
