import controls from '@/components/mixins/controls/controls'

export default {
  mixins: [controls],
  watch: {
    //хз в чем косяк, но поиск по advertId при выбранных филтрах из компонента Filters работает только так
    //при том, что поиск по телефон в этой же ситуции отрабатывает без watch
    //как вариант телефон передается объектом, advertId строкой
    /* note: закоментировал этот вотч, так как теперь работает без него, и метод используемый в нем тоже вроде не нужен.*/
      'localFilters.advertId': {
        handler (val, oldVal) {
          this.advertIdHandler(val, oldVal)
        },
        immediate: true
      },
    /*note: вотч ниже как и все с ним связанное, кажется, нигде не используется.*/
    'localFilters.clientCompilationId': {
      handler () {
        if (this.clientAdvertId && this.clientId && !this.localFilters.clientCompilationId) {
          this.handleClientIdFilter(this.clientId)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    advertIdHandler (val, newVal) {
      if (val !== newVal && this.localFilters.advertId) {
        this.handleIdFilter(this.localFilters.advertId)
      }
    },
    handleIdFilter (data) {
      if (data) {
        if (!Array.isArray(data)) {
          data = data.split(',')
        }
        if (this.isFilterActive()) {
          this.$emit('list', { requestParams: { filters: { advertId: data, isVerified: null } } })
        } else {
          this.localFilters = { ...this.localFilters, ...{ phoneFilter: '', advertId: data } }
        }
      } else {
        this.localFilters = { ...this.localFilters, ...{ advertId: data } }
      }
    }
  }
}
